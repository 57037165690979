import { Component } from '@mycolorway/tao';
import eventListenerMixin from 'next/javascripts/mixins/event-listener-mixin';
import 'next/stylesheets/components/presales-support-widget';

export default Component('tr-presales-support-widget', {
  properties: {
    isPopoverOpen: Boolean,
  },

  mixins: [eventListenerMixin],

  connected() {
    this.listenTo(this, {
      mouseenter: this.togglePopover.bind(this),
      mouseleave: this.togglePopover.bind(this),
    });
    this.popover = this.querySelector('.popover');
  },

  disconnected() {
    this.stopListening();
  },

  togglePopover(e) {
    e.preventDefault();
    if (this.isPopoverOpen) {
      this.closePopover();
    } else {
      this.openPopover();
    }
  },

  openPopover() {
    if (this.isPopoverOpen) return;
    this.popover.dataset.state = 'opening';
    setTimeout(() => {
      this.popover.dataset.state = 'open';
    }, 1);
    this.isPopoverOpen = true;
  },

  closePopover() {
    if (!this.isPopoverOpen) return;
    this.popover.dataset.state = 'closing';
    setTimeout(() => {
      if (!this.isPopoverOpen) {
        this.popover.dataset.state = 'close';
      }
    }, 400);
    this.isPopoverOpen = false;
  },
});
